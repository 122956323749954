<template>
  <div class="main" style="padding:0">
 

    <!-- header end-->
    <!-- body start -->
    <div class="bodybox">
 
<iframe
         style="border:none"
         :width="windowWidth-16"
         :height="searchTableHeight"
         v-bind:src="reportUrl"
       ></iframe>



    </div>
 

  </div>
</template>

<script>
// 公共样式

import "../assets/css/common.css";
export default {
  data() {
    return {
      reportUrl:"http://data.siluhuizhan.com/",
      searchTableHeight:1000,
      activeIndex: "7",
      drawer: false,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
    
      arr: ["zt1", "zt2", "zt3", "zt4", "zt5", "zt6", "zt7", "zt8"],
      aa: ["不限", "周末双休", "五险一金", "包吃住", "带薪年假"],
      indexaa: 0,
      bb: ["不限", "综合", "电商", "文化创意", "互联网"],
      indexbb: 0,
      height: "400px",
      input2: "",
      obj: {},
      compObj: {},
    };
  },

  watch: {
    windowWidth(val) {
      let that = this;
      // console.log("实时屏幕宽度：", val);
      if (val > 990) {
        that.drawer = false;
        that.height = "400px";
      } else if (val <= 900 && val > 400) {
        that.height = "200px";
      } else {
        that.height = "100px";
      }
    },
  },
  methods: {
    //   企业详情
      getComInfor(id){

this.$router.push({path:'/enterpriseDetails',query:{SysID:id}})
      },
    getnewsList(id) {
      let that = this;
      that
        .$post("/api/Interaction/Obj", {
          SysID: id,
        })
        .then(function (data) {
          console.log(data);
          let datalist = data.Obj.Obj
         
         
         
          that.obj = datalist;
          let company =data.Obj.CompanyObj 
          company.Logo = that.urlOA+company.Logo.substr(1)
          that.compObj = company;

        })
        .catch((error) => {
          console.log(error);
        });
    },
    choeseaa(index) {
      console.log(index);
      this.indexaa = index;
    },
    choesebb(index) {
      console.log(index);
      this.indexbb = index;
    },
    handleSelect(e) {
      let that = this;
      console.log(e);
      that.$router.push(e);
      setTimeout(() => {
        that.drawer = false;
      }, 500);
    },
    getlogin() {
      this.$router.push("/login");
    },
    register() {
      this.$router.push("/register");
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    
  },
  mounted() {
    console.log(this.urlOA);
    let SysID = this.$route.query.SysID;

    console.log(SysID);
    var that = this;
    that.getnewsList(SysID);
    let windowWidth = that.windowWidth;
    console.log(windowWidth);
    if (windowWidth < 900 && windowWidth > 400) {
      that.height = "200px";
    } else if (windowWidth < 400) {
      that.height = "150px";
    }
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight; // 高
        that.windowWidth = window.fullWidth; // 宽
      })();
    };
  },
};
</script>


<style scoped  lang="scss">
// 内容区

.textbox {
  width: 1230px;
  margin-left: 50%;
  transform: translateX(-50%);
  //   background-color: #475669;
  padding-top: 50px;
  overflow: hidden;
  .leftbox {
    float: left;
    width: 900px;
    .synctitle {
      width: 100%;
      height: 22px;
      line-height: 22px;
      padding-left: 10px;
      font-size: 22px;
      font-weight: 500;
      border-left: 4px solid #3273dc;
      box-sizing: border-box;
      margin-bottom: 20px;
    }
    .syncbox {
      position: relative;
      width: 100%;
      padding-left: 10px;
      box-sizing: border-box;
      .boxtitle {
        font-size: 24px;
        font-weight: 500;
        width: 700px;
      }
      .boxtags {
        width: 80%;
        padding: 10px 0;
        // box-sizing: border-box;
        overflow: hidden;
        span {
          float: left;
          padding: 2px 10px;
          background-color: #ed982c;
          color: #fff;
          font-size: 14px;
          border-radius: 2px;
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
      .box_btn {
        position: absolute;
        bottom: 6px;
        right: 10px;
        padding: 10px 30px;
        background-color: #3fbcbb;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
      }
    }
    .htmlbox {
      width: 860px;
      margin-top: 20px;
      margin-left: 10px;
      padding: 20px;
      border-radius: 5px;
      background-color: #fff;
      box-shadow: 0px 0px 20px 0 rgb(105 105 105 / 30%);
      ::v-deep p{
        line-height: 30px;
        font-size: 15px;
        color: #555;
      }
         ::v-deep li{
        line-height: 30px;
        font-size: 15px;
        color: #555;
      }
    }
    .combox {
      width: 860px;
      margin-top: 20px;
      margin-left: 10px;
      padding: 20px;
      border-radius: 5px;
      background-color: #fff;
      box-shadow: 0px 0px 20px 0 rgb(105 105 105 / 30%);
      margin-bottom: 100px;
      overflow: hidden;
      img {
        float: left;
        cursor: pointer;
        height: 40px;
      }
      span {
        float: left;
        height: 40px;
        cursor: pointer;
        line-height: 40px;
        font-size: 20px;
        margin-left: 20px;
        font-weight: 700;
      }
    }
  }
  .rightbox {
    float: left;
    width: 300px;
    margin-left: 30px;
    .card {
      padding: 10px 0;
      background-color: #f9f9f9;
      box-sizing: border-box;
      margin-bottom: 50px;
      .card_title {
        border-left: 4px solid #3273dc;
        height: 20px;
        line-height: 20px;
        padding-left: 20px;
        box-sizing: border-box;
        font-size: 18px;
      }
      .tags_box {
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
        overflow: hidden;
        span {
          float: left;
          padding: 8px 20px;
          margin: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
      }
    }
  }
}
.othernews {
  margin-top: 10px;
  height: 30px;
  width: 260px;
  margin-left: 20px;
  //   line-height: 30px;
  border-top: 1px solid #eee;
  font-size: 14px;
  //   padding-left: 10px;
  box-sizing: border-box;
  cursor: pointer;
}
.othernews:hover {
  color: #3273dc;
}
.othernews span {
  display: inline-block;
  height: 30px;
  padding-right: 10px;
  //   padding-top: 10px;
  color: #3273dc;
  font-size: 24px;
  font-weight: 800;
  //   line-height: 30px;
  box-sizing: border-box;
}
.comlist{
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    overflow: hidden;
     cursor: pointer;
    .imgbox{
        float: left;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        overflow: hidden;
        img{
            height: 100%;
            width: 100%;
        }
    }
    .inforbox{
        float: left;
        margin-left: 10px;
        width: 200px;
       
    .comnames{
        height: 30px;
        line-height: 30px;
        font-size: 16px;
    }
    .money{
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        color: #999;
    }

    }
}
.htmlbox >>> ul li {
  line-height: 100px;
}
.aaa {
  display: none;
}
@media (max-width: 1201px) {
  .textbox {
    width: 940px;
  }

  h5 {
    padding: 10px;
    box-sizing: border-box;
  }
}
@media (max-width: 991px) {
  .aaa {
    display: block;
  }

  .bannerbox img {
    height: 100%;
  }
  .main .textbox {
    width: 100%;
    overflow: hidden;
    padding-top: 10px;
  }
}
@media (max-width: 590px) {
}
</style>